import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import UIkit from 'uikit';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component
export class LandscapeMode extends Vue {
  private objScreenText: ScreenText = new ScreenText();
  public isMobile: boolean = APP_UTILITIES.checkMobile();
  public deviceFormatCheck: string = navigator.userAgent;

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  closePopup() {
    UIkit.modal('#landscape-mode-popup').hide();
  }

  mounted() {
    if (this.isMobile) {
      this.handleOrientationChange();
      if (
        this.deviceFormatCheck.indexOf('iPhone') != -1 ||
        this.deviceFormatCheck.indexOf('iPad') != -1
      ) {
        window.addEventListener('resize', this.handleOrientationChange);
      }
      else {
        window.addEventListener(
          'orientationchange',
          this.handleOrientationChange
        );
      }
    }
  }

  private handleOrientationChange() {
    let orientation = '';
    if (
      this.deviceFormatCheck.indexOf('iPhone') != -1 ||
      this.deviceFormatCheck.indexOf('iPad') != -1
    ) {
      orientation =
        window.innerWidth > window.innerHeight
          ? 'landscape-primary'
          : 'portrait-primary';
    }
    else {
      orientation = window.screen.orientation.type;
    }
    if (orientation.includes('landscape')) {
      UIkit.modal('#landscape-mode-popup').show();
    }
    else {
      UIkit.modal('#landscape-mode-popup').hide();
    }
  }

  destroyed() {
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange
    );
    window.removeEventListener('resize', this.handleOrientationChange);
  }
}
