

export default class API_CONST {
  public static readonly API_ROOT = 'http://api.root.endpoint';
  public static readonly GET_USER_ROLE = `${API_CONST.API_ROOT}/role`;
  public static readonly GET_USER_INFO = `${API_CONST.API_ROOT}/userinfo`;
  public static readonly ACCOUNTS = 'accounts';
  public static readonly PROGRAMS = 'programs';
  public static readonly NEW_ACCOUNTS = 'accounts';
  public static readonly NEW_ACCOUNTS_DATA_ENDPOINT = 'auto-save/account';
  public static readonly FETCH_ACCOUNTS_DATA_ENDPOINT = 'accounts';
  public static readonly SAVE_NEW_ACCOUNTS_ENDPOINT = 'accounts';
  public static readonly UPDATE_ACCOUNTS_ENDPOINT = 'accounts';
  public static readonly AUTO_SAVE_POST_ACCOUNT_ENDPOINT = 'accounts';
  public static readonly CONFIGURE_PROGRAM = 'programs';
  public static readonly ACCOUNT_BY_ID = 'accounts';
  public static readonly VALIDATE_TOKEN = 'users/token/validation';
  public static readonly UPDATE_PROGRAM = 'programs/status';
  public static readonly RESET_PROGRAM = 'tasks/reset';
  public static readonly EDIT_PROGRAM_UPDATE = 'programs';
  public static readonly FIRST_LOGIN_ACCOUNT_INFO = 'accounts/first-login/user';
  public static readonly USERID = 'users/';
  public static readonly INFO = 'info/';
  public static readonly FETCH_CONFIG_PROGRAM = 'programs';
  public static readonly QUESTIONAIRE = 'question';
  public static readonly AUTO_SAVE_PROGRAM = 'auto-save/program';
  public static readonly GET_PROGRAM_DATA = 'programs';
  public static readonly PROGRAM_PLAN_ENDPOINT = 'tasks';
  public static readonly PROGRAM_PLAN_EVENT_ENDPOINT = 'events';
  public static readonly DASHBOARD_TASK_EVENT_ENDPOINT = 'calendar';
  public static readonly CREATE_PROGRAM_TASK = 'tasks';
  public static readonly THOUGHTINDUSTRYURL = 'https://bellxcel.thoughtindustries.com/access/jwt?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOiJUaW1lLm5vdy50b19pIiwiZW1haWwiOiJuZWVyYWoubmVnaUBtYWdpY2VkdGVjaC5jb20iLCJleHRlcm5hbEN1c3RvbWVySWQiOiIxOTkiLCJmaXJzdE5hbWUiOiJOZWVyYWoiLCJsYXN0TmFtZSI6Ik5lZ2kiLCJyZXR1cm5UbyI6Ii9sZWFybi92aWRlby9zY2hvbGFyLW1hbmFnZW1lbnQtcmVjcnVpdG1lbnQtZW5yb2xsbWVudC1hbmQtYXR0ZW5kYW5jZSIsImNsaWVudElkIjoiYWY4ODMwODMtNTUwYS00ZjJlLWE2NGUtYTQ2ODU3YmZlYjRiIiwiY2xpZW50U2t1IjoiTUFHSUMtU1NPIiwicm9sZSI6IkxlYXJuZXJzIiwic3R1ZGVudExpY2Vuc2VJZHMiOiJhODMyZmI1Ny1mYjhhLTQzMGQtOGZmMC03M2IzNTJkNDFhODciLCJzdHVkZW50TGljZW5zZVNrdXMiOiJNQUdJQy1NQUlOIn0.oBlyLLMbPR1UJ4ZVkJqduZZDb-HpIDbBduRP13Jw3FI';
  public static readonly FETCH_CONFIG_PROGRAM_BY_ID = 'programs';
  public static readonly LMS_LINKS = 'links';
  public static readonly LMS_COURSE_STATUS = 'userCourses';
  public static readonly UPDATE_PROGRAM_TASK = 'task';
  public static readonly ADD_EVENT = 'event';
  public static readonly UPDATE_EVENT = 'event';
  public static readonly DELETE_EVENT = 'event';
  public static readonly COPY_EVENTS = 'event/copy';
  public static readonly DELETE_TASK = 'task';
  public static readonly UPLOAD_CSV = 'roster/upload';
  public static readonly UPLOAD_CSV_STAFF = 'staffs/roster';
  public static readonly UPLOAD_DOCUMENTS = 'roster/scholar/document';
  public static readonly DOWNLOAD_DOCUMENTS = 'roster/scholar/document';
  public static readonly HTML_TO_PDF_DOWNLOAD = 'documents/pdf';
  public static readonly STAFF_REQUIRED_HEADER = 'staff-headers-required';
  public static readonly STAFF_HEADERS = 'staffs/staff-bx-csv-headers';
  public static readonly GET_STAFF_AUTOSAVE_ROASTER = 'staffs/roster';
  public static readonly CONFIGURE_STAFF_ROASTER = 'staffs/roster';
  public static readonly DOWNLOAD_TEMP = 'roster/template';
  public static readonly REQUIRED_HEADER = 'headers-required';
  public static readonly CHECK_SCHOLAR_STATUS = 'roster/status';
  public static readonly HEADERS = 'roster/bx-csv-headers';
  public static readonly CONFIGURE_ROASTER = 'roster';
  public static readonly GET_TABLE_DATA = 'roster/csv-data';
  public static readonly GET_AUTOSAVE_ROASTER = 'auto-save/roster';
  public static readonly GET_ROASTER_STEP5 = '/roster';
  public static readonly GET_ROASTER_ERROR = '/roster/errors';
  public static readonly DELETE_ROASTER_DATA = 'roster/file';
  public static readonly DOWNLOAD_ROASTER = 'roster/download';
  public static readonly GET_MANAGE_USERS = 'users/type';
  public static readonly RESEND_INVITE = 'users/resend-invite';
  public static readonly GET_ROLES = 'roles';
  public static readonly USERS = 'users';
  public static readonly PARTNER_USERS = 'users/partner';
  public static readonly GET_ACCOUNTS = 'accounts/list';
  public static readonly GET_PANORAMAS = '/panoramas';
  public static readonly GET_ACCOUNT_TYPE = '/account-types';
  public static readonly GET_LICENSES_BY_CLIENT = 'licenses';
  public static readonly SUBMIT_ATTEDANCE = 'attendance';
  public static readonly GET_CLASS_ROOM = 'roster/classrooms';
  public static readonly GET_SCHOLAR_ATTEDANCE = 'attendance';
  public static readonly MARK_SCHOLAR_ATTEDANCE = 'attendance';
  public static readonly REVERT_SCHOLAR_ATTEDANCE = 'attendance/revert';
  public static readonly GET_ALL_HOLIDAYS = 'holidays';
  public static readonly GET_PROGRAMS_BY_ACCOUNT = 'programs/list';
  public static readonly GET_STAFF = 'roles/staffs';
  public static readonly GET_SITE_BY_PROGRAMID = 'sites';
  public static readonly POST_SITE_DATA = 'sites';
  public static readonly GET_TASKS_PROD = 'task-products';
  public static readonly POST_STAFF = 'staffs';
  public static readonly GET_CLASSROOMS = 'roster/classrooms';
  public static readonly CLASSROOMS = 'classrooms';
  public static readonly GET_STAFF_LIST = 'staffs';
  public static readonly POST_SCHOLAR_DATA = 'roster/scholars';
  public static readonly POST_SCHOLAR_DATA_Enrollment = 'roster/scholar';
  public static readonly POST_FAMILY_DATA = 'roster/family';
  public static readonly GET_DROPDOWN_VALUES = 'headers-dropdown';
  public static readonly EDIT_PROGRAM = 'programs';
  public static readonly GET_RECRUITMENT_BY_PROGRAMID = 'dashboard/recruitment';
  public static readonly UPDATE_SCHOLAR_DATA = 'roster/scholars/';
  public static readonly DELETE_FAMILY = 'roster/scholars?familyId=';
  public static readonly GET_ADDED_SCHOLAR = 'roster/scholars?scholarId=';
  public static readonly GET_RETENTION = 'dashboard/retention';
  public static readonly GET_RETENTION_BY_SITE = 'dashboard/retentionbysite';
  public static readonly GET_ADA = 'dashboard/ada';
  public static readonly GET_ADA_BY_SITE = 'dashboard/adabysite';
  public static readonly GET_STAFF_BY_ID = 'staffs/class';
  public static readonly HTML_TO_PDF = 'documents/pdf';
  public static readonly QUALITY_SCORE = 'qualityscores';
  public static readonly QUALITY_WELCOME = 'qualityscores/userinfo';
  public static readonly GET_DOWNLOAD_STAFFCSV = 'staffs/download';
  public static readonly GET_DOWNLOAD_USERS_CSV = 'users/downloadusers';
  public static readonly ACCOUNT_ADMIN_LIST_COUNT = 5;
  public static readonly USER_INFO = 'userinfo';
  public static readonly USER_ID = '?userId=';
  public static readonly USER_PROGRAMID = 'programId=';
  public static readonly ASSIGN_TASKS = 'task/assign';
  public static readonly SKIP_TASKS = 'task/bulkstatusupdate';
  public static readonly GET_TASKS = 'tasks?';
  public static readonly OWNER_INFO = 'ownerinfo';
  public static readonly PROG_TASK = 'task';
  public static readonly PROGRAM = 'program/';
  public static readonly TASK = 'task/';
  public static readonly DELETE_BULK_ROSTER = '/roster';
  public static readonly CANCEL_SITE = '/roster/cancel/site';
  public static readonly ADD_PROGRAM_EVENT = '/event';
  public static readonly GET_EVENTS = 'event/events-holidays?';
  public static readonly GET_ADA_BY_SITE_REPORT = 'dashboard/downloadadareport';
  public static readonly GET_DAILY_ATTEDANCE_BY_SCHOLAR_REPORT = 'attendance/downloadbysite';
  public static readonly ADD_FILES = '/files/plannertype/';
  public static readonly ADD_FILE_EVENTS = 'event/files/type/';
  public static readonly DISCUSSION = '/discussion';
  public static readonly COPY_FILES = '/copy/task/';
  public static readonly SITE_ID = '?siteId=';
  public static readonly PROGRAM_ID = '&programId=';
  public static readonly GET_DAILY_ATTEDANCE_WITH_SCHOLAR_DETAILS = 'dashboard/scholarada';
  public static readonly UPLOAD_EVENT_FILE = 'files/plannertype/';
  public static readonly UPLOAD_HOLIDAY_FILE = 'Holidays/uploadfile';
  public static readonly DOWNLOAD_HOLIDAY_FILE = 'planner-blob/holidays/';
  public static readonly DOWNLOAD_EVENT_FILE = 'planner-blob/events/';
  public static readonly DOWNLOAD_TASK_FILE = 'planner-blob/tasks/';
  public static readonly GET_QUALITY_SCREENER_PDF = 'qualityscores/downloadpdf';
  public static readonly SITE_NOTIFICATION_INFO = 'sitenotificationinfo';
  public static readonly GET_QUALITY_SCORE_CSV = 'qualityscores/downloadcsv';
  public static readonly GETQUALITY_DATA = 'qualityscores/getgraphdata';
  public static readonly ROSTER_STEP3 = 'staffs/roster-step3';
  public static readonly UPDATE_ROSTER_STEP3 = 'staffs/roster/row-data';
  public static readonly STAFF_COMMIT = 'staffs/roster/commit';
  public static readonly ACCOUNT = 'account/';
  public static readonly UPDATE_SITENOTIFICATION = 'update-sitenotification?';
  public static readonly USER_EMAIL = '/users/email';
  public static readonly SIGNALR = 'rosterHub';
  public static readonly SITESONACCOUNTID = '/accounts/accprogsiteinfo/account/';
  public static readonly ADD_SITE_SCHEDULE_DAYS = '/roster/scholars';
  public static readonly GET_PRODUCTS = '/products';
  public static readonly GET_SCHOLAR_FAMILIES_INFO = 'roster/families/scholar';
  public static readonly ENROLLMENT = '/enrollment';
  public static readonly SUBMISSION_SITE_MAPPING = '/submission-site-mapping';
  public static readonly GET_GUARDIAN_FAMILIES_INFO = 'roster/guardian';
  public static readonly DOWNLOAD_CSV_GUARDIAN = 'family/downloadcontacts';
  public static readonly UPDATE_SCHOLAR_CONTACTS_DATA = 'roster';
  public static readonly COLLABORATOR = 'collaborator';
  public static readonly APPLICATION = 'application';
  public static readonly ACCOUNT_STATUS = '/accounts/update-status?accountIds=';
  public static readonly GET_PROGRAMS_LIST = 'programs/list';
  public static readonly GET_ROSTER_AUTOSAVE = 'roster/autosave';
  public static readonly GET_SUBSCRIPTION_DETAILS = 'subscription';
  public static readonly DECLINE_ENROLLMENT_DATA = 'enrollment/decline-status';
  public static readonly GET_SCHOLAR_DETAILS = 'roster/scholar/attendance/drawer';
  public static readonly ATTENDANCE_DOWNLOAD = 'attendance/download';
  public static readonly ATTENDANCE_RESET = 'attendance/reset';
  public static readonly USERS_LIST = 'users/list';
  public static readonly BULK = 'users/bulk';
  public static readonly BULK_SITES = 'sites/bulk';
  public static readonly PROGRAM_DELETE = 'programs';
  public static readonly PRIMARY_ADMIN = 'primary-admin';
  public static readonly ANALYTICS_PROGRAM_RECRUITMENT = 'analytics/programrecruitment/account';
  public static readonly ENABLE_DISABLE_ACCOUNT = '/accounts/bulkstatus';
  public static readonly DELETE_BULK_SITES = '/sites/bulk';
  public static readonly BULK_INVITE = '/bulk-invite';
  public static readonly BULK_REVOKE = '/bulk-revoke';
  public static readonly ENABLE_DISABLE_PROGRAM = '/programs/bulk-status';
  public static readonly RESET_TASK_PROGRAM = '/programs/task/bulk-reset';
  public static readonly BULK_PRIVILEGE = 'users/privilege';
  public static readonly UNDO_BULK_PRIVILEGE = 'users/undo-privilege';
  public static readonly ANALYTICS_TOTAL_SUBMISSIONS = '/analytics/totalsubmissions';
  public static readonly PROGRAM_SESSIONS_LIST = '/sessions/program';
  public static readonly SCHOLARS_APPLICATIONS_DROPDOWN_SUBMISSION_STATUS = 'application/applicationbystatus/';
  public static readonly SCHOLARS_ATTENDANCE_DROPDOWN_SITE = 'attendance/download/ada/site';
  public static readonly SCHOLARS_ATTENDANCE_DROPDOWN_WEEK = 'attendance/download/ada';
  public static readonly SCHOLARS_ATTENDANCE_DROPDOWN_DAY = 'attendance/download/ada';
  public static readonly SCHOLARS_ATTENDANCE_DROPDOWN_CHECK_IN = 'attendance/download/dailyattendance/checkin';
  public static readonly SCHOLARS_ATTENDANCE_DROPDOWN_CHECK_OUT = 'attendance/download/dailyattendance/checkout';
  public static readonly ANALYTICS_ADA = 'analytics/ada';
  public static readonly PROGRAM_SITES_APPLICATION = 'application/analytics/program/';
  public static readonly PROGRAM_SITES_ANALYTIC_LIST = '/analytics/enrolled/scholars';
  public static readonly APPLICATION_ANALYTIC_LIST = '/analytics/submissions/timeline';
  public static readonly CHAT_FAMILY_ENGAGEMENT = '/family/engagement/team';
  public static readonly FAMILY_ENGAGEMENT = '/family/engagement';
  public static readonly GET_FAMILY_SCHOLARS_DATA = 'family/scholarsdata';
  public static readonly SEND_GUARDIAN_INVITE = 'guardian/sendinvite';
  public static readonly GUARDIANS_INVITE_STATUS = 'guardian/status';
  public static readonly GUARDIANS_REVOKE_ACCESS = 'guardian/revoke-access';
  public static readonly GET_FAMILY_GUARDIAN_DATA = 'family/guardians';
  public static readonly DELETE_CONTACTS = 'family/deletecontacts';
  public static readonly GET_PARTICIPANTS_DATA = 'roster/participants';
  public static readonly GET_PARTICIPANTS_DATA_CSV = 'roster/participants/{accountId}/download';
  public static readonly DELETE_PARTICIPANTS = 'family/deleteparticipants';
  public static readonly GET_PARTICIPANTS_COLUMN_CONFIG = 'roster/columns';
  public static readonly ATTENDENCE_SITE_ANALYTICS_LIST = '/analytics/attendancecompletion';
  public static readonly ANALYTICS_RETENTION = 'analytics/retention';
  public static readonly ATTENDENCE_COMPLETION_PROGRAM_DETAILS = '/analytics/programattendancecompletion';
  public static readonly SCHOLARS_APPLICATIONS_DROPDOWN_SUBMISSION_WEEKLY = 'application/applicationsweekly/';
  public static readonly QUALITY_ANALYTIC_LIST = '/analytics/quality-scores';
  public static readonly COMPLETION_DOWNLOAD_CSV = '/analytics/completion/download-csv';
  public static readonly RESET_PASSWORD = '/password/temp';
  public static readonly ROLEWISE_USERS = '/users-rolewise';
  public static readonly RETENSION_DOWNLOAD_CSV = '/analytics/retention/download-csv';
  public static readonly ENROLLMENT_DOWNLOAD_CSV = '/analytics/enrolled/scholars/download-csv';
  public static readonly PROFESSIONAL_LEARNING_DETAILS = '/analytics/professional-learning/courses-completed';
  public static readonly PROFESSIONAL_LEARNING_DOWNLOAD_CSV = '/analytics/professional-learning/download/courses-completed';
  public static readonly LOCATIONS_END_POINT = '/Location';
  public static readonly SITE_LOCATIONS_END_POINT = '/SiteLocations';
  public static readonly AVAILABLE_SITE_LOCATIONS_END_POINT = '/SiteLocations/availableSiteLocations';
  public static readonly SESSIONS_END_POINT = '/Sessions';
  public static readonly PROGRAM_SESSIONS_ENDPOINT = '/Sessions/Program';
  public static readonly ADA_SCHOLAR_DETAILS_DOWNLOAD_CSV = '/dashboard/scholar/attendance';
  public static readonly SCHOLAR_DAILY_ATTENDANCE_DOWNLOAD_CSV = '/attendance/daily/scholar';
  public static readonly GET_STRIPE_ONBOARDING_LINK = '/payment/onboarding-link';
  public static readonly GET_STRIPE_ONBOARDING_STATUS = '/payment/onboarding-status';
  public static readonly GET_TAX_RATES = '/payment/gettaxrates';
  public static readonly STORE_POLICY = '/accounts/policy';
  public static readonly CONVENIENCE_FEE = '/fee/convenience';
  public static readonly OFFLINE_PAYMENTS = '/customcheckout';
  public static readonly COLLECTIONS = '/collections';
  public static readonly DISCOUNT = '/discount';
  public static readonly PAYMENT_PLAN = '/paymentplan';

}
