import { VueConstructor } from 'vue';
import { analyticsEventNames } from './events';

/**
 * The key used to inject the analytics service provided by the root component.
 */
export const AnalyticsInjectionKey = Symbol('bxl-analytics');

/**
 * Provides the analytics service to all components rendered in the app.
 * @param {VueConstructor} Vue - The Vue constructor.
 * @param {AnalyticsService} analyticsService - The analytics service to provide.
 */
export function provideAnalytics(Vue: VueConstructor, analyticsService: AnalyticsService): void {
  /**
   * Since app level provide is not available in the current Vue version,
   * we are using a root mixin instead.
   */
  Vue.mixin({
    provide: {
      [AnalyticsInjectionKey]: analyticsService
    }
  });
}

export type AnalyticsEventName = typeof analyticsEventNames[keyof typeof analyticsEventNames];
export type AnalyticsEventProperties = Record<string, unknown>;

export interface AnalyticsService {
  /**
   * Tie a user to their actions and record traits about them.
   * @param {string} userId - Unique identifier for the user.
   * @param {AnalyticsEventProperties} traits - Additional traits associated with the user.
   */
  identify(userId: string, traits?: AnalyticsEventProperties): void;
  /**
   * Record whenever a user sees a page, along with any additional properties about the page.
   * @param {AnalyticsEventName} eventName - The name of the event to track.
   * @param {AnalyticsEventProperties} properties - Additional properties associated with the page.
   */
  page(eventName: AnalyticsEventName, properties?: AnalyticsEventProperties): void;

  /**
   * Track any action a user performs, along with any additional properties about the action.
   * @param {AnalyticsEventName} eventName - The name of the event to track.
   * @param {AnalyticsEventProperties} properties - Additional properties associated with the event.
   */
  track(eventName: AnalyticsEventName, properties?: AnalyticsEventProperties): void;

  /**
   * Tie a user to a group and record traits about the group.
   * @param {string} groupId - Unique identifier for the group.
   * @param {AnalyticsEventProperties} properties - Additional properties associated with the event.
   */
  group(groupId: string, properties?: AnalyticsEventProperties): void;
}

export interface AnalyticsConfig {
  /**
   * The name of the event to track.
   */
  eventName: AnalyticsEventName;
  /**
   * Additional properties associated with the event.
   */
  properties?: AnalyticsEventProperties;
}
