import { Status } from '@/Model/shared/types';
import { DropdownListOptions } from '@/ui-components/dropdownListBx/types';

export interface StripeAccountResponse {
  accountId: number;
  onboardingStatus: OnboardingStatus;
}

export enum OnboardingStatus {
  NotStarted = 1,
  InProgress = 2,
  Completed = 3
}

export interface PriceRequest {
  price: number;
  billingCycle?: SessionBillingCycle;
  billingCycleCount: number;
}

export interface GetTaxRatesResponse {
  taxRates: TaxRate[];
}

export interface TaxRate {
  id: string;
  displayName: string;
  percentage: number;
}

export interface PriceResponse {
  billingCycle: SessionBillingCycle;
  billingCycleCount: number;
  price: number;
  priceId: string;
}

export enum PriceType {
  OneTime = 0,
  Recurring = 1,
}

export interface RecurringPaymentIntervalDropdownOptions {
  interval: SessionBillingCycle;
  displayText: string;
}

export interface RecurringPaymentBillingCycleDropdownOptions {
  billingCycle: number;
  displayText: string;
}

export enum SessionBillingCycle {
  OneTime = 0,
  Weekly = 1,
  Monthly = 2,
}

export enum CouponDuration {
  Once = 'once',
  Repeating = 'repeating',
  Forever = 'forever'
}

export interface Coupon {
  id: string;
  name: string;
  amountOff?: number;
  percentOff?: number;
  duration: CouponDuration;
  durationInMonths?: number;
  redeemBy?: string; // Received from the API as a DateTime string
}

export interface PromoCode {
  id: string;
  code: string;
  coupon: Coupon;
  expiresAt?: string; // Received from the API as a DateTime string
}

export interface StorePolicy {
  enabled: boolean;
  url: string;
}

export interface AddUpdateStorePolicyPayload extends StorePolicy {
  accountId: number;
}

export interface ConvenienceFeeGetResponse {
  id: number;
  name: string;
  description: string;
  status: ConvenienceFeeStatus;
  feeType: ConvenienceFeeType;
  feeValue: number;
  taxRate: TaxRate | null;
}

export interface ConvenienceFeePutRequest {
  id?: number;
  accountId: number;
  name: string;
  description: string;
  status: ConvenienceFeeStatus;
  feeType: ConvenienceFeeType;
  feeValue: number;
  taxRate: TaxRate | null;
}

export type ConvenienceFeeStatus = Extract<Status, Status.Active | Status.Inactive>;

export enum ConvenienceFeeType {
  Percentage = 0,
  FixedAmount = 1
}

export interface CustomFeeResponse extends Omit<ConvenienceFeeGetResponse, 'id' | 'description' | 'taxRate'> {
  productId: string;
  description?: string;
  taxRate?: TaxRate;
}

export enum OfflinePaymentsAutoEnrollOptions {
  Enroll = 10,
  Waitlist = 11
}

export interface OfflinePaymentsOption {
  id: number;
  accountID: number;
  name: string;
  instructions: string;
  autoEnrollStatusId: number;
  isEnabled: boolean;
}

export enum AmountUnitType {
  PERCENT_RATE = '1',
  FIXED_AMOUNT = '2',
  NOT_SET = 'NULL'
}

export enum DiscountType {
  MULTI_PARTICIPANT = 1,
  EARLY_REGISTRATION = 2
}

export interface MultiParticipantRule{
  participants: number;
  amount: number;
}

export enum TimeRangeType {
  MONTHS = 1,
  DAYS = 2,
  WEEKS = 3
}

export interface EarlyRegistrationRule {
  timeRangeUnit: number;
  timeRangeType: TimeRangeType;
  amount: number;
}

export type DiscountRule  = MultiParticipantRule[] | [EarlyRegistrationRule];

export interface Discount {
  id: number;
  name: string;
  discountTypeId: DiscountType;
  amountUnitType: AmountUnitType;
  appliesTo: string;
  description: string;
  rules: DiscountRule;
  isActive: boolean;
  accountId: number;
}

export enum StateModalType {
  ERROR,
  WARNING
}

/**
 * Type for handling form data for payment plan form
 */
export interface PaymentPlanForm {
  id: number;
  numberOfPaymentsList: DropdownListOptions;
  frequencyList: DropdownListOptions;
  selectedValue: {
    numberOfPayments: number;
    frequency: number;
    isItDuplicated: boolean;
    /**
     * property used to store the existing payment plan id when the user is editing a payment plan
     */
    existingPaymentPlanId?: number;
  };
}

export enum PaymentPlanFrequency {
  WEEKLY = 1,
  BIWEELKY = 2,
  MONTHLY = 3
}

export enum PaymentPlanInterval {
  DAYS = 1,
  WEEKS = 2,
  MONTHS = 3
}

export interface PaymentPlanConfiguration {
  id: number | null;
  paymentPlanID: number | null;
  paymentIntervalCount: number;
  paymentInterval: PaymentPlanInterval;
}

export interface PaymentPlan {
  id: number | null;
  accountId: number;
  minimumOrderAmount: number | null;
  disablePaymentsCount: number | null;
  disablePaymentsInterval: PaymentPlanInterval;
  paymentPlanConfigurations: PaymentPlanConfiguration[];
  isActive: boolean;
}