import { Vue } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import handlerStatesCities from 'countrycitystatejson';
import statesCities from 'countries-states-cities';
import { coreIds, GuardianDto } from '@/Model/model';
import LocalStorage from '@/utilities/localStorageUtil';
import UIkit from 'uikit';
import jwt_decode from 'jwt-decode';
import { getModule } from 'vuex-module-decorators';

import { ErrorMessages } from '@/lang/errorText';
import { ApiErrorResponse, ApiErrorResponseVariant, ApiErrorType } from '@/Model/errors/api/types';
import { Day, DayAbbreviation } from '@/Model/selectedDays/types';
import { ScreenText } from '@/lang/ScreenText';
import { ToastType } from '@/Model/toastType';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import { LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { ContactData } from '@/Model/shared/types';
import { getaccountbyid } from '@/services/userService/users-api';
import { ToastPosition } from '@/Model/toastPosition';
import { EarlyRegistrationRule, TimeRangeType } from '@/Model/payments/types';
import { AttendanceSettingsData } from '@/Model/attendance';

const globalModule = getModule(GlobalModule, store);

let lastColor: string = '';
export default class APP_UTILITIES extends Vue {
  /**
   * Set a new context to be used for evaluating feature flags
   * @param newContext - the new context to be used
   * @returns LDContext - the new context
   */
  public static async setLDContext(newContext: LDContext): Promise<LDContext> {
    return globalModule.storedLDClient.identify(newContext);
  }

  /**
   * Set a new Launch Darkly client to be used for all launch darkly actions
   * @param client - the new LDClient to be used
   */
  public static async setLDClient(client: LDClient): Promise<void> {
    return globalModule.mutateLDClient(client);
  }

  /**
   * Gets the current Launch Darkly client
   * @returns LDClient - the current Launch Darkly  lient
   */
  public static async getLDClient(): Promise<LDClient> {
    return globalModule.storedLDClient;
  }

  /**
   * Gets the current Launch Darkly context
   * @returns LDContext - the current Launch Darkly context
   */
  public static async getLDContext(): Promise<LDContext> {
    return globalModule.storedLDClient.getContext();
  }

  /**
   * Get the value of a feature flag
   * @param flag The key of the flag to evaluate
   * @returns boolean - the value of the flag
   */
  public static async getFeatureFlag(
    flag: typeof APP_CONST.FEATURE_KEYS[keyof typeof APP_CONST.FEATURE_KEYS]
  ) {
    return globalModule.storedLDClient.variation(flag);
  }

  /**
   * Gets all feature flags for the current context
   */
  public static async getAllFeatureFlags(): Promise<any> {
    return globalModule.storedLDClient.allFlags();
  }

  /**
   * Waits for the Launch Darkly client to be initialized
   * @returns boolean - whether the Launch Darkly client has been initialized
   */
  public static async waitForLDClient(): Promise<void> {
    return globalModule.storedLDClient.waitForInitialization();
  }

  public static getCookie(name: string) {
    if (name == 'auth_token') {
      if (globalModule.storedAuthToken !== null) {
        return globalModule.storedAuthToken;
      }
    }

    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  public static setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(
        date.getTime() +
        days
        * APP_CONST.HOUR
        * APP_CONST.MINUTES
        * APP_CONST.SECOND
        * APP_CONST.MILISECOND
      );
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  private static accessCookie(cookieName: string) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      const temp = allCookieArray[i].trim();
      if (temp.indexOf(name) == 0) {
        return temp.substring(name.length, temp.length);
      }
    }
    return '';
  }

  public static deleteCookie(cookieName: string) {
    if (this.accessCookie(cookieName)) {
      document.cookie = `${cookieName}=; expires=${new Date()}; path=/;`;
    }
  }

  public static removeCookies() {
    const res = document.cookie;
    const multiple = res.split(';');
    for (let i = 0; i < multiple.length; i++) {
      const key = multiple[i].split('=');
      if (!key[0].includes(APP_CONST.USERNAME)) {
        document.cookie = `${key[0]}=; expires=${new Date()}; path=/;`;
      }
    }
  }

  public static capitalize(s: string) {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  public static fetchStates(countryName: string) {
    return Object.keys(
      handlerStatesCities.getCountryByShort(countryName).states
    ).filter((response: any) => {
      if (response != 'AP' && response != 'AA' && response != 'AE') {
        return response;
      }
    });
  }

  public static getStatesShort(countryID: number) {
    return Object.values(statesCities.getStatesOfCountry(countryID));
  }

  public static fetchCities(countryName: string) {
    return Object.values(
      handlerStatesCities.getCountryByShort(countryName).states
    )
      .filter((fiterItem: any, index) => fiterItem[index] !== undefined)
      .map((item: any, index: number) => {
        if (item[index]) {
          return item[index].name;
        }
      });
  }

  public static removeStates(states: any, statesToRemove: any) {
    states = states.filter(
      (item: any) => !statesToRemove.includes(item.state_code)
    );
    return states;
  }

  public static isNumericInput(event: any) {
    const key = event.keyCode;
    return (
      (key >= APP_CONST.KEY_48 && key <= APP_CONST.KEY_57) ||
      (key >= APP_CONST.KEY_96 && key <= APP_CONST.KEY_105)
    );
  }

  public static isModifierKey(event: any) {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === APP_CONST.KEY_35 ||
      key === APP_CONST.KEY_36 ||
      key === APP_CONST.KEY_8 ||
      key === APP_CONST.KEY_TAB ||
      key === APP_CONST.KEY_ENTER ||
      key === APP_CONST.KEY_46 ||
      (key > 36 && key < 41) ||
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === APP_CONST.KEY_65 ||
          key === APP_CONST.KEY_67 ||
          key === APP_CONST.KEY_86 ||
          key === APP_CONST.KEY_88 ||
          key === APP_CONST.KEY_90))
    );
  }

  public static formatNumber(phoneNumberString: any) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  }

  public static checkBrowserStatus() {
    return navigator.onLine;
  }

  public static formatDate(
    date: string,
    monthShort?: boolean,
    daySingle?: boolean
  ) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const dateObj = new Date(date);
    const month = monthShort
      ? this.monthFormat(date)
      : monthNames[dateObj.getMonth()];
    const day = daySingle
      ? dateObj.getDate()
      : String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = month + ' ' + day + ', ' + year;
    return output;
  }

  public static dayFormat(date: string) {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '');
    const output = day;
    return output;
  }

  public static monthFormat(date: string) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const dateObj = new Date(date);
    const month = monthNames[dateObj.getMonth()];
    const output = month;
    return output;
  }

  public static fullMonthFormat(date: string | Date) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const dateObj = new Date(date);
    const month = monthNames[dateObj.getMonth()];
    const output = month;
    return output;
  }

  public static checkTime(i: any) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  public static formatShortDate(date: string) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj
      .getFullYear()
      .toString()
      .substr(0, 4);
    const output = month + '/' + day + '/' + year;
    return output;
  }

  public static formatShortDateFullYear(date: string) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear().toString();
    const output = month + '/' + day + '/' + year;
    return output;
  }

  public static formatFullYearDate(date: string) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear().toString();
    const output = year + '-' + month + '-' + day;
    return output;
  }

  public static getFullDate(date: any) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = month + '/' + day + '/' + year;
    return output;
  }
  public static getFullDateWith2DigitYear(date: any) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj
      .getFullYear()
      .toString()
      .substr(-2);
    const output = month + '/' + day + '/' + year;
    return output;
  }

  public static getSlashedDates(date: any) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = month + '-' + day + '-' + year;
    return output;
  }

  public static formatCorrectDate(date: string) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj
      .getFullYear()
      .toString()
      .substr(2, 2);
    const h = this.checkTime(dateObj.getHours());
    const m = this.checkTime(dateObj.getMinutes());
    const output = month + '/' + day + '/' + year + ' ' + h + ':' + m;
    return output;
  }

  public static getCurrentDate() {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const dateObj = new Date();
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = month + ' ' + day + ', ' + year;
    return output;
  }

  public static requiredFields(
    actualData: any,
    validation: any,
    createUser?: boolean
  ) {
    let validateData: any = {};
    if (createUser) {
      const roleId =
        actualData.userRoles && actualData.userRoles[APP_CONST.ZERO].roleId
          ? actualData.userRoles[APP_CONST.ZERO].roleId
          : 0;
      validateData = APP_UTILITIES.markRequiredFields(roleId, validation);
    }
    else {
      validateData = validation;
    }

    let isFormInvalid = APP_CONST.FALSE;
    let requiredFieldsFilled = APP_CONST.FALSE;
    for (const field in validateData) {
      if (!validateData[field].type && !actualData[field]) {
        validateData[field].error = APP_CONST.TRUE;
        isFormInvalid = APP_CONST.TRUE;
        requiredFieldsFilled = APP_CONST.TRUE;
      }
      else if (
        validateData[field].type === 'array' &&
        validateData[field].required
      ) {
        const checkField = actualData['userRoles'][APP_CONST.ZERO][field];
        validateData[field].error = checkField
          ? APP_CONST.FALSE
          : APP_CONST.TRUE;
        if (!checkField) {
          isFormInvalid = validateData[field].error;
        }
      }
      else if (validateData[field].errorAlpha) {
        const checkField = this.validateAlphaOnly(actualData[field]);
        const checkMinimumLength = this.checkMinimumLength(actualData[field]);
        validateData[field].alphaCharError = checkField
          ? APP_CONST.FALSE
          : APP_CONST.TRUE;
        validateData[field].minimumCharError = checkMinimumLength
          ? APP_CONST.TRUE
          : APP_CONST.FALSE;
        if (!checkField || checkMinimumLength) {
          isFormInvalid = validateData[field].alphaCharError;
          isFormInvalid = validateData[field].minimumCharError;
        }
      }
      else {
        validateData[field].error = APP_CONST.FALSE;
      }
    }
    return { isFormInvalid, validateData, requiredFieldsFilled };
  }

  private static markRequiredFields(roleId: number, validateData: any) {
    const dataToValidate = JSON.parse(JSON.stringify(validateData));
    if (roleId === APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN) {
      dataToValidate.accountId.required = APP_CONST.TRUE;
    }
    if (roleId === APP_CONST.ROLE_TYPE_PROGRAM_ADMIN) {
      dataToValidate.accountId.required = APP_CONST.TRUE;
      dataToValidate.programId.required = APP_CONST.TRUE;
      dataToValidate.siteId.required = APP_CONST.FALSE;
    }
    if (
      roleId === APP_CONST.ROLE_TYPE_SESSION_ADMIN ||
      roleId === APP_CONST.ROLE_TYPE_STAFF
    ) {
      dataToValidate.accountId.required = APP_CONST.TRUE;
      dataToValidate.programId.required = APP_CONST.TRUE;
      dataToValidate.siteId.required = APP_CONST.TRUE;
    }
    return dataToValidate;
  }

  public static formatAMPM(dt: string) {
    const date = new Date(dt);
    let hours = date.getHours();
    let minutes = date.getMinutes() as any;
    const ampm = hours >= 12
      ? 'PM'
      : 'AM';
    hours = hours % 12;
    hours = hours
      ? hours
      : 12;
    minutes = minutes < 10
      ? '0' + minutes
      : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  public static formatSmallerAMPM(dt: string) {
    const date = new Date(dt);
    let hours = date.getHours();
    const minutes = date.getMinutes() as any;
    const ampm = hours >= 12
      ? 'PM'
      : 'AM';
    hours = hours % 12;
    hours = hours
      ? hours
      : 12;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  public static getDaysNameByDate(date: any) {
    const days = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];
    const d = new Date(date);
    return days[d.getDay()];
  }

  public static getDaysNameByDateAttedance(date: any) {
    const days = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];
    const d = new Date(date);
    return days[d.getDay()];
  }

  public static convertDateInGMT(date: string) {
    return new Date(date);
  }

  public static sortDates(data: any) {
    return (
      data &&
      data.length > 0 &&
      data.sort(function (a: any, b: any) {
        const dateA: any = new Date(a.startDate);
        const dateB: any = new Date(b.startDate);
        return dateA - dateB;
      })
    );
  }

  public static removeSpecialCharacters(val: string) {
    if (!val) {
      return '';
    }
    const cleaned: any =
      val && val.charAt(0) == '0'
        ? ''
        : ('' + val).replace(/\D/g, '').toString();
    return cleaned;
  }

  public static validateEmail(
    email: string,
    emails: string[],
    roleId: number
  ): boolean {
    let valid = false;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      if (roleId && roleId < APP_CONST.FOUR) {
        for (const domain of emails) {
          if (email.indexOf(domain, email.length - domain.length) !== -1) {
            valid = true;
            break;
          }
        }
      }
      else {
        valid = true;
      }
    }
    return valid;
  }

  public static validateEmaiOnly(email: string, emails?: string[]): boolean {
    let valid = false;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      valid = true;
    }
    else {
      valid = false;
    }
    return valid;
  }

  public static getFullDayName(date: any) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const d = new Date(date);
    return days[d.getDay()];
  }

  public static getMonthNameByDate(date: any) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const dateObj = new Date(date);
    return monthNames[dateObj.getMonth()];
  }

  public static checkMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  public static validateAlphaOnly(char: string) {
    const hasNumber = /^[\u00AA-\u017E\a-zA-Z\. '-]*$/;
    if (!hasNumber.test(char)) {
      return false;
    }
    else {
      return true;
    }
  }

  public static validateNumber(char: string) {
    const number = /^[0-9]*$/;
    if (number.test(char)) {
      return true;
    }
    else {
      return false;
    }
  }

  public static validateAlphaAndSpecialChar(char: string) {
    const hasChar = /[@$%*~`!#^&()+=|\{}:;,<>/?_]/;
    const hasNumber = /[0-9]+/;
    if (!hasChar.test(char) || !hasNumber.test(char)) {
      return false;
    }
    else {
      return true;
    }
  }

  public static validateSpecialChar(char: string) {
    const format = /[`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/;
    if (format.test(char)) {
      return char.replace(format, '');
    }
    else {
      return char;
    }
  }

  public static checkMinimumLength(char: string) {
    if (char.length > 50) {
      return true;
    }
    else {
      return false;
    }
  }

  public static coreids() {
    const userDt: string | null = APP_UTILITIES.getCookie('user_role');
    const userRoles: coreIds[] = userDt && JSON.parse(userDt);
    let programId: number = 0,
      siteId: number = 0,
      roleId: number = 0,
      accountId: number = 0,
      id: number = 0;
    userRoles &&
      userRoles.length &&
      userRoles.forEach(item => {
        if (item.hasOwnProperty('programId')) {
          programId = item.programId;
        }
        if (item.hasOwnProperty('accountId')) {
          accountId = item.accountId;
        }
        if (item.hasOwnProperty('siteId')) {
          siteId = item.siteId;
        }
        if (item.hasOwnProperty('roleId')) {
          roleId = item.roleId || 0;
        }
      });

    let userId: any = APP_UTILITIES.getCookie('user_id');
    userId = parseInt(userId);
    return {
      id: userId,
      programId,
      siteId,
      roleId,
      accountId: +accountId
    };
  }

  public static getAccountIdByCookies() {
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    const roles = JSON.parse(userRoles);
    return roles.map((item: any, index: any) => {
      if (item.hasOwnProperty('accountId')) {
        return item.accountId;
      }
    });
  }

  public static checkCookie(name: string) {
    const match = document.cookie.match(
      RegExp('(?:^|;\\s*)' + name + '=([^;]*)')
    );
    return match
      ? match[1]
      : null;
  }

  static async roleBasedNav() {
    let learningExperienceDirectAccess = false;
    const learningExperienceDirectAccessEnabled = await APP_UTILITIES.getFeatureFlag(
      APP_CONST.FEATURE_KEYS.learningExperienceDirectAccess
    );

    const { roleId, accountId } = APP_UTILITIES.coreids();
    const highestRoleId = APP_UTILITIES.getUserHighestRole();

    if (accountId && learningExperienceDirectAccessEnabled) {
      const { data: { isDirectAccess = false } } = await getaccountbyid({ accountId });
      learningExperienceDirectAccess = isDirectAccess;
    }

    const decideNav = JSON.parse(JSON.stringify(APP_CONST.NAV_DECIDER));
    const isSuperUserDrillThrough: boolean = JSON.parse(APP_UTILITIES.getCookie('super_user_drilldown') || 'false');
    const navRoleId = learningExperienceDirectAccess && !isSuperUserDrillThrough
      ? highestRoleId
      : roleId;

    const navView =
      (decideNav.length &&
        decideNav.find((item: any) => item.roleType == navRoleId)) ||
      [];

    if (learningExperienceDirectAccess) {
      navView.navView = navView.learningExperienceDirectAccessNavView || [];
      navView.isLearningExperienceDirectAccess = true;
    }

    const customContentCollectionsEnabled = await APP_UTILITIES.getFeatureFlag(
      APP_CONST.FEATURE_KEYS.customContentCollections
    );

    // Remove the "My Collections" menu item if custom collections flag is not enabled
    if (!customContentCollectionsEnabled) {
      // Find the Parent Navigation Item with the title "Learning"
      const contentCollectionsIndex = navView.navView.findIndex(
        (item: any) => item.title === APP_CONST.LEARNING
      );

      if (contentCollectionsIndex > -1) {
        navView.navView[contentCollectionsIndex].nestedDrop = navView.navView[contentCollectionsIndex].nestedDrop.filter(
          (item: any) => item.title !== APP_CONST.APP_ROUTES.MY_COLLECTIONS.menuTitle
        );
      }
    }

    return navView;
  }

  static getOldCmsNav() {
    return {
      title: 'Learning & Resources',
      isNested: false,
      route: 'navigateUrl',
      image: 'resources.svg',
      class: 'resources',
      isSelected: false,
      dropdownClass: 'blue-list'
    };
  }

  // static configureProgramNav() {
  //     const {roleId} = APP_UTILITIES.coreids();
  //     let decideNav = JSON.parse(JSON.stringify(APP_CONST.NAV_DECIDER_Configure_Program));
  //     const navView = (decideNav.length && decideNav.find((item: any) => item.roleType == roleId)) || [];
  //     return navView;
  // }

  public static debounce(func: any) {
    let timer: any;
    return function (event: any) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(func, 200, event);
    };
  }
  public static isMobile() {
    if (document.documentElement.clientWidth < 769) {
      return true;
    }
    else if (document.documentElement.clientWidth >= 769) {
      return false;
    }
    return false;
  }
  public static tabletCheck() {
    if (document.documentElement.clientWidth <= 600) {
      return false;
    }
    else if (document.documentElement.clientWidth > 600) {
      return true;
    }
    return false;
  }

  public static isMobilePortrait() {
    if (document.documentElement.clientWidth <= 992) {
      return true;
    }
    else if (document.documentElement.clientWidth > 992) {
      return false;
    }
    return false;
  }

  public static mobileAndTabletCheck() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        ) ||
        (navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(navigator.platform))
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  public static getColorCode(email: string) {
    let sum = 0;
    let index = 0;
    for (let i = 0; i < email.length; i++) {
      sum += email.charCodeAt(i);
      index = sum % APP_CONST.COLORS.length;
    }
    return (lastColor = APP_CONST.COLORS[index]);
  }
  public static convertTime12to24(time12h: any) {
    if (this.checkTimeIs24(time12h)) {
      time12h = this.convert12hrFormateTime(time12h);
    }
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes]: any = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  public static convert12hrFormateTime(time: string) {
    const theAdd = new Date(
      APP_UTILITIES.getFullDate(new Date()) + ' ' + this.replaceAMPM(time)
    );
    const finalDate = theAdd.setMinutes(theAdd.getMinutes());
    return new Date(finalDate).toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric'
    });
  }
  public static convert12hrFormateTimeFromDate(date: string) {
    const datetime = new Date(date);
    const finalDate = datetime.getTime();
    return new Date(finalDate)
      .toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric'
      })
      .toLowerCase();
  }
  public static validateHhMm12Format(timeFormate: string) {
    const regexp = /^(0?[1-9]|1[012])(:[0-5]\d)[APap][mM]$/;
    if (!this.checkTimeIs24(timeFormate)) {
      return regexp.test(timeFormate);
    }
    else {
      return this.validateHhMm24Format(timeFormate);
    }
  }
  public static validateHhMm12FormatwithoutColon(timeFormate: string) {
    const regexp = /^(0?[1-9]|1[012])[APap][mM]$/;
    return regexp.test(timeFormate);
  }
  public static checkTimeIs24(time: string) {
    const theAdd = new Date(
      this.getFullDate(new Date()) + ' ' + this.replaceAMPM(time)
    );
    if (theAdd.getHours() > 12 || isNaN(theAdd.getHours())) {
      return true;
    }
    return false;
  }

  public static convertTime(time: string) {
    if (time.length >= 6) {
      return `${time.substring(0, time.length - 2)} ${time
        .substring(time.length - 2, time.length)
        .toUpperCase()}`;
    }
    else {
      return `${time.substring(0, time.length - 2)}:00 ${time
        .substring(time.length - 2, time.length)
        .toUpperCase()}`;
    }
  }
  public static replaceAMPM(time: string) {
    time = time.replace('PM', '');
    time = time.replace('AM', '');
    return time;
  }
  public static validateHhMm24Format(timeFormate: string) {
    const regexp = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return regexp.test(this.replaceAMPM(timeFormate).trim());
  }
  public static compareTwoTimes(startDate: string, endDate: string) {
    const dateA = new Date(startDate);
    const dateB = new Date(endDate);
    const dayRelativeDifference =
      dateB.getHours() * 60 +
      dateB.getMinutes()
      - dateA.getHours() * 60
      - dateA.getMinutes();
    if (dayRelativeDifference < 0) {
      return { negativeTimeError: true, timeError: false };
    }
    else if (dayRelativeDifference >= 0 && dayRelativeDifference < 30) {
      return { negativeTimeError: false, timeError: true };
    }
    return { negativeTimeError: false, timeError: false };
  }

  public static diff_minutes(dt2: Date, dt1: Date) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);
  }

  public static downLoadFile(
    response: any,
    fileName: string,
    fileHeader: string
  ) {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yy = date.getFullYear();
    response.data = !response.data
      ? fileHeader
      : response.data;
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const fileData = new Blob([BOM, response.data], {
      type: 'application/csv;charset=UTF-8'
    });
    const csvFileData = document.createElement('a');
    csvFileData.href = URL.createObjectURL(fileData);
    csvFileData.setAttribute(
      'download',
      fileName + mm + '_' + dd + '_' + yy + '.csv'
    );
    csvFileData.click();
  }

  public static downloadPDF(response: any, fileName: string) {
    const fileData = new Blob([response.data], {
      type: 'application/pdf'
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(fileData);
    link.download = fileName + '.pdf';
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  }

  public static formatCorrectDateWithTime(date: string) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear().toString();
    const output = month + '/' + day + '/' + year + ' ' + '00' + ':' + '00';
    return output;
  }
  public static hasOnlyAlphabetDotSpace(myString: any) {
    let isValid = false;
    const regex = /^[\u00AA-\u017E\a-zA-Z\. '-]*$/;
    isValid = regex.test(myString);
    return isValid;
  }

  public static authTokenKeyToManage() {
    const authToken = APP_UTILITIES.getCookie('auth_token');
    if (authToken) {
      const tokenVal = JSON.parse(atob(authToken.split('.')[1]));
      return tokenVal.userId;
    }
  }

  public static setSortingOnSession(
    identity: string,
    keyField: string,
    sortOrder: string,
    customColumn?: string
  ) {
    const tempObj = {
      key: keyField,
      dir: sortOrder,
      customColumn: customColumn
    };
    LocalStorage.saveInLocalStorage(identity, JSON.stringify(tempObj));
  }

  public static getSortingOnSession(key: string): string | null {
    return LocalStorage.getFromLocalStorage(key)
      ? LocalStorage.getFromLocalStorage(key)
      : '';
  }
  public static getFileNameAndtype(name: any) {
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return { fileName: fileName, ext: ext };
  }
  public static renameFile(filesArray: any) {
    const newFilearray = new Array();
    const tempArray = new Array();
    for (const r in filesArray) {
      if (
        tempArray.indexOf(
          this.replaceWhiteSpaceFromFileName(filesArray[r].fileName)
        ) > -1
      ) {
        let index = 1;
        while (
          tempArray.indexOf(
            this.replaceWhiteSpaceFromFileName(
              filesArray[r].fileNameWithoutType
            ) +
            '(' +
            index +
            ')' +
            '.' +
            this.getFileNameAndtype(filesArray[r].fileName).ext
          ) > -1
        ) {
          index++;
        }
        const str = filesArray[r].fileNameWithoutType + '(' + index + ')';
        newFilearray.push(str);
        tempArray.push(this.replaceWhiteSpaceFromFileName(str));
      }
      else {
        newFilearray.push(filesArray[r].fileName);
        tempArray.push(
          this.replaceWhiteSpaceFromFileName(filesArray[r].fileName)
        );
      }
    }
    return newFilearray;
  }
  public static replaceWhiteSpaceFromFileName(name: any) {
    if (name) {
      return name.replace(/\s/g, '');
    }
    return '';
  }

  public static showToastMessage(message: string, status: ToastType, position: ToastPosition = ToastPosition.TopCenter) {
    let icon = '';
    switch (status) {
      case ToastType.Success:
        icon = '<i class="fa-solid fa-circle-check"></i>';
        break;
      case ToastType.Warning:
        icon = '<i class="fa-solid fa-triangle-exclamation"></i>';
        break;
      case ToastType.Error:
        icon = '<i class="fa-solid fa-circle-exclamation"></i>';
        break;
      case ToastType.Primary:
        icon = '<i class="fa-solid fa-circle-info"></i>';
        break;
    }
    const messageWithIcon = `<div class="toast-wrapper">${icon} ${message}</div>`;
    UIkit.notification.closeAll();
    UIkit.notification({
      message: messageWithIcon,
      status: status,
      pos: position,
      timeout: 5000
    });
  }

  public static getUserRole(id: number) {
    if (id == APP_CONST.ONE) {
      return 'BX SuperAdmin';
    }
    else if (id == APP_CONST.TWO) {
      return 'BX Admin';
    }
    else if (id == APP_CONST.FOUR) {
      return 'Account Admin';
    }
    else if (id == APP_CONST.FIVE) {
      return 'Program Admin';
    }
    else if (id == APP_CONST.SIX) {
      return 'Session Admin';
    }
    else if (id == APP_CONST.SEVEN) {
      return 'Staff';
    }
    else if (id == APP_CONST.NINE) {
      return 'None';
    }
  }
  public static getStatusById(statusId: number) {
    if (statusId == APP_CONST.ZERO) {
      return 'N/A';
    }
    else if (statusId == APP_CONST.ONE) {
      return APP_CONST.ACTIVE;
    }
    else if (statusId == APP_CONST.TWO) {
      return APP_CONST.INACTIVE;
    }
    else if (statusId == APP_CONST.THREE) {
      return APP_CONST.DELETED;
    }
    else if (statusId == APP_CONST.FOUR) {
      return APP_CONST.INVITE_PENDING;
    }
    else if (statusId == APP_CONST.FIVE) {
      return APP_CONST.INVITE_EXPIRED;
    }
  }

  public static getDateWithTextFieldFormat(date: any) {
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();

    return month + ' - ' + day + ' - ' + year;
  }

  public static getPosOfElement(el: any) {
    for (
      var lx = 0, ly = 0;
      el != null;
      lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
    ) { }
    return { x: lx, y: ly };
  }

  public static hasNonNumericChar(char: string) {
    const withNoDigits = char.replace(/[0-9]/g, '');
    if (withNoDigits.length < 1) {
      return false;
    }
    else {
      return true;
    }
  }

  public static setFilteredColumnsAttendace(key: string, data: any) {
    LocalStorage.saveInLocalStorage(key, JSON.stringify(data));
  }

  public static deleteFromLocalStorage(key: string) {
    LocalStorage.deleteFromLocalStorage(key);
  }

  public static findAndSplice(
    array: any[],
    propertyToMatch: string,
    valueToMatch: any
  ) {
    const index =
      array.findIndex(el => el[propertyToMatch] == valueToMatch) ||
      APP_CONST.MINUS_ONE;
    if (index >= APP_CONST.ZERO) {
      array.splice(index, APP_CONST.ONE);
    }
    return array;
  }

  public static setFilteredColumns(identity: string, data: any) {
    LocalStorage.saveInLocalStorage(identity, JSON.stringify(data));
  }

  public static getFilteredColumns(key: string): string | null {
    return LocalStorage.getFromLocalStorage(key)
      ? LocalStorage.getFromLocalStorage(key)
      : '';
  }

  public static getInitialLetters(firstName: string, lastName: string) {
    return (
      firstName
        .trim()
        .charAt(0)
        .toUpperCase() +
      lastName
        .trim()
        .charAt(0)
        .toUpperCase()
    );
  }

  public static sortAscending(list: any, sortOn: string) {
    list &&
      list.sort((a: any, b: any) => {
        if (a[sortOn] < b[sortOn]) {
          return APP_CONST.MINUS_ONE;
        }
        if (a[sortOn] > b[sortOn]) {
          return APP_CONST.ONE;
        }
        return APP_CONST.ZERO;
      });
    return list;
  }

  public static forceNumberInput(event: KeyboardEvent, allowDecimal = false) {
    let regex = /[0-9]/;
    if (allowDecimal) {
      regex = /[0-9.]/;
    }
    const isNumeric = regex.test(event.key);
    if (isNumeric === false) {
      event.preventDefault();
    }
  }

  /**
   * Checks whether the passed `error` object is an instance of `ApiErrorResponse`.
   * @param error The object to check.
   * @returns Returns `true` if the object is an instance of `ApiErrorResponse`, otherwise `false`.
   */
  public static isApiErrorResponse(error: unknown): boolean {
    let isKnownApiErrorResponse = false;
    if (error === Object(error)) {
      try {
        const errorVariant = error as ApiErrorResponseVariant;
        let toTest = errorVariant;
        if (
          errorVariant &&
          'data' in errorVariant &&
          typeof errorVariant.data === 'object'
        ) {
          if ('message' in errorVariant.data) {
            toTest = errorVariant.data.message;
          }
          else {
            toTest = errorVariant.data;
          }
        }
        if (toTest) {
          const knownObj: ApiErrorResponse = {
            name: ApiErrorType.Error_ProgramNameExistsInAccount,
            value: '',
            resourceNotFound: false,
            searchedLocation: ''
          };
          const keysTypes = Object.entries(toTest).map(kt => ({
            key: kt[0],
            type: typeof kt[1]
          }));
          const knownKeysTypes = Object.entries(knownObj).map(kt => ({
            key: kt[0],
            type: typeof kt[1]
          }));
          if (keysTypes.length === knownKeysTypes.length) {
            const sameKeysAndTypes = keysTypes.every(
              kt =>
                !!knownKeysTypes.find(
                  kkt => kkt.key === kt.key && kkt.type === kt.type
                )
            );
            if (sameKeysAndTypes) {
              isKnownApiErrorResponse = true;
            }
          }
        }
      }
      catch (e) {
        console.error(`Unhandled error checking ${error}`, e);
      }
    }
    return isKnownApiErrorResponse;
  }

  /**
   * Converts the passed error to an `ApiErrorResponse` object. If the passed `error` is not an
   * instance of `ApiErrorResponse`, uses `ApiErrorType.Error_Unknown` for `name` and attempts to create
   * an error message for `value` based on the `APP_UTILITIES.getErrorMessage` function.
   * @param error The object to try to convert to `ApiErrorResponse`.
   * @returns An `ApiErrorResponse` object.
   */
  public static errorToApiErrorResponse(error: unknown): ApiErrorResponse {
    const isKnownApiErrorResponse = APP_UTILITIES.isApiErrorResponse(error);
    if (isKnownApiErrorResponse) {
      const errorVariant = error as ApiErrorResponseVariant;
      if ('data' in errorVariant && typeof errorVariant.data === 'object') {
        if ('message' in errorVariant.data) {
          return errorVariant.data.message;
        }
        return errorVariant.data;
      }
      return errorVariant as ApiErrorResponse;
    }
    const errorMessage = APP_UTILITIES.getErrorMessage(error);
    return {
      name: ApiErrorType.Error_Unknown,
      value: errorMessage,
      resourceNotFound: false,
      searchedLocation: ''
    };
  }

  /**
   * Attempts to return the `message` from an `Error` object. If an object is
   * not an instance of `Error`, attempts to pull the value from one of the following props:
   * `error.message`, `error.data.value`, `error.data.Message`. If none of these
   * are found, returns the generic `Common_Error` message from
   * `@/lang/errorText/ErrorMessages.getErrorMessages('Common_Error')` instead.
   * @param error The object to attempt to retrieve the error message from.
   * @returns The error message string.
   */
  public static getErrorMessage(error: unknown): string {
    if (error && typeof error === 'string') {
      return error;
    }

    let errorMessage = ((new ErrorMessages().getErrorMessages(
      'Common_Error'
    ) as unknown) as { title: string }).title; // Cast to unknown because it actually returns an object, not a string like the function definition says
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    else if (error === Object(error)) {
      // To account for other existing patterns in the app
      // Ideally should be an Error object though
      try {
        const errorAny = error as any;
        const keys = Object.keys(errorAny);
        if (keys.includes('message') && errorAny.message) {
          errorMessage = errorAny.message;
        }
        else if (keys.includes('data')) {
          if (errorAny.data && typeof errorAny.data === 'string') {
            errorMessage = errorAny.data;
          }
          else if (errorAny.data.value) {
            errorMessage = errorAny.data.value;
          }
          else if (errorAny.data.Message) {
            errorMessage = errorAny.data.Message;
          }
        }
      }
      catch (e) {
        console.error(
          'Unhandled error type for getErrorMessage',
          `'${typeof error}'`,
          e
        );
      }
    }
    return errorMessage;
  }

  /**
   *
   * @param short Use the shortened day abbreviation instead of the three-letter abbreviation (defaults to `false`).
   * @returns An array of `DayAbbreviation` objects containing the `Day` and abbreviation string.
   */
  public static getDaysAbbreviations(
    short: boolean = false
  ): Array<DayAbbreviation> {
    const screenText = new ScreenText();
    const dayKeys: Array<string> = Object.keys(Day).filter(k =>
      isNaN(Number(k))
    ); // Filter out the numeric keys
    const daysAbbreviations: Array<DayAbbreviation> = dayKeys.map(day => ({
      day: Day[day as keyof typeof Day],
      abbreviation: screenText.getScreenText(
        `${day}_ABBR${short
          ? '_SHORT'
          : ''}`
      )
    }));
    return daysAbbreviations;
  }

  /**
   * Interprets the passed `dateTime` string as a JS `Date` object as a UTC date/time.
   * @param dateTime The dateTime string to interpret as UTC.
   * @returns The passed `dateTime` string interpreted as a UTC `Date` object.
   */
  public static dateTimeStringAsUTCDateTime(dateTime: string): Date {
    const localDate = new Date(dateTime);
    let result = localDate;
    if (!isNaN(localDate.getTime())) {
      result = new Date(
        Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate(),
          localDate.getHours(),
          localDate.getMinutes(),
          localDate.getSeconds(),
          localDate.getMilliseconds()
        )
      );
    }
    return result;
  }

  /**
   * Extracts the date from the passed `Date` object, sets a new UTC `Date` to the exact same year, month, and date in the
   * original passed date with the time set to `00:00:00.000`, and returns the UTC zeroed date as a local `Date`.
   * @param date The base JS `Date` object to extract the date from.
   * @returns The local date relative to the passed `date` as a UTC `Date` object set at time `00:00:00.000`.
   */
  public static dateAsZeroedTimeUTCDate(date: Date): Date {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
  }

  public static getAccountId(): number {
    const accountIdCookie = APP_UTILITIES.getCookie('accountId');
    const accountId = accountIdCookie
      ? Number.parseInt(accountIdCookie)
      : 0;
    return accountId;
  }

  public static getAccountName(): string {
    const accountNameCookie = APP_UTILITIES.getCookie('accountName');
    const accountName = accountNameCookie
      ? accountNameCookie
      : '';
    return accountName;
  }

  /**
   *
   * @returns The number value of the `highest_role` cookie for the current user.
   */
  public static getUserHighestRole(): number {
    const highestRoleCookie = APP_UTILITIES.getCookie('highest_role');
    const highestRole = +(highestRoleCookie || 0);
    return highestRole;
  }

  public static setUserCookies(oktaToken: string, includeRole: boolean = true) {
    const {
      roleId: highestRoleId,
      userId,
      userRoles,
      firstName,
      lastName
    } = jwt_decode(oktaToken) as any;

    APP_UTILITIES.setCookie('okta_user_role', JSON.stringify(highestRoleId), 1);

    // Casting to a number to avoid logic issues between strings and numbers values
    const highestRoleIdVal = Number(highestRoleId);

    const highestRole = userRoles.find(
      (el: { roleId: number }) => el.roleId == highestRoleIdVal
    );
    highestRole.roleId = highestRoleIdVal <= 4
      ? highestRoleIdVal
      : 0;

    const userRole = highestRoleIdVal > 3
      ? [highestRole]
      : userRoles;
    APP_UTILITIES.setCookie(
      'user_cookies_user_role',
      JSON.stringify(userRole[0].roleId),
      1
    );
    const accountId = userRole[0] && userRole[0].accountId;
    const name = `${firstName} ${lastName}`;

    if (accountId) {
      APP_UTILITIES.setCookie('accountId', accountId, 1);
    }
    globalModule.mutateAuthToken(oktaToken);
    APP_UTILITIES.setCookie(
      'isAccountAdmin',
      highestRoleIdVal == APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN
        ? 'true'
        : '',
      1
    );
    APP_UTILITIES.setCookie("user_id", userId, 1);
    APP_UTILITIES.setCookie("user_name", name, 1);
    APP_UTILITIES.setCookie("highest_role", String(highestRoleIdVal), 1);

    // These cookies only need to be set on the first render
    // Since there are mutated when user navigates to the partners home
    if (includeRole) {
      APP_UTILITIES.setCookie("user_role", JSON.stringify(userRole), 1);
    }
  }

  public static getUserID(): number {
    const userIdCookie = APP_UTILITIES.getCookie('user_id');
    const userId = userIdCookie
      ? Number.parseInt(userIdCookie)
      : 0;
    return userId;
  }

  public static getUserName(): string {
    return APP_UTILITIES.getCookie('user_name') || '';
  }

  /**
 * e.g 14289 Rainbow, Apt# 45, Chino, AL 19481
 * @param guardianData
 */
  public static formatAddress(guardianData: ContactData | GuardianDto): string {
    try {
      const { address: { address1, address2, city, state, zip } = { address1: '', address2: '', city: '', state: '', zip: '' } } = guardianData;
      const address = [address1, address2, city, state, zip].filter((item) => item);
      const addressFormatted = address.map((item, index) => {
        if (index === address.length - 1) {
          return ` ${item}`;
        }
        if (index === address.length - 2) {
          return `${item} `;
        }
        return `${item}, `;
      }).join('');
      return addressFormatted || '--';
    }
    catch (error) {
      return '--';
    }
  }

  public static downloadCsv(data: string, fileName: string = 'download.csv') {
    const temp = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = temp;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
  }

  /**
 * Generates a random alphanumeric ID.
 * @param {number} length - The length of the generated ID.
 * @returns {string} The generated alphanumeric ID.
 */
  public static generateRandomAlphanumericId(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }


  /**
  * Function that addds a symbol to the label depending on the isFixedAmount value for automatic discounts feat
  */
  public static addSymbol(label: string | number, isFixedAmount: boolean): string {
    const symbol = isFixedAmount
      ? '$'
      : '%';
    return isFixedAmount
      ? `${symbol}${label}`
      : `${label}${symbol}`;
  }

  /**
  * Function that calculates the timeframe label for automatic discounts feat
  */
  public static earlyRegistrationDateLabel(earlyRegistration: EarlyRegistrationRule): string {

    const plural = earlyRegistration.timeRangeUnit > 1
      ? 's'
      : '';

    return `${earlyRegistration.timeRangeType === TimeRangeType.DAYS
        ? 'day'
        : earlyRegistration.timeRangeType === TimeRangeType.MONTHS
          ? 'month'
          : 'week'
      }${plural}`;
  }

  /**
   * Gets stored attendance settings mapping from local storage.
   * @returns The stored attendance settings mapping.
   */
  public static getLocalStoredAttendanceSettingsMapping(): Record<string, AttendanceSettingsData | undefined> {
    const settings = localStorage.getItem('attendanceSettingsMapping');
    if (settings) {
      return JSON.parse(settings);
    }
    return {};
  }

  /**
   * Sets the attendance settings mapping in local storage.
   * @param data The attendance settings mapping to store in local storage.
   */
  public static setLocalStoredAttendanceSettingsMapping(data: Record<string, AttendanceSettingsData>) {
    localStorage.setItem('attendanceSettingsMapping', JSON.stringify(data));
  }
}
